export default {
  ADD_ITEM (state, item) {
    state.data.unshift(item)
  },
  SET_LIST (state, centres) {
    state.data = centres
  },
  UPDATE_ITEM (state, payload) {
    const dataIndex = state.data.findIndex((p) => p.slug === payload.slug);
    Object.assign(state.data[dataIndex], payload)
  },
  REMOVE_ITEM (state, itemId) {
    const ItemIndex = state.data.findIndex((p) => p.slug === itemId);
    state.data.splice(ItemIndex, 1)
  }
}
